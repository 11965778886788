<template>
  <!-- 待学习 -->
  <div class="toLearn">
    <van-nav-bar
      :title="$t('toLearn.LearnTxt1')"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
      :fixed="true"
    />
    <div class="fixedH" style="height: 1.22667rem"></div>
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t('toLearn.LearnTxt2')"
      @load="onLoad"
    >
      <van-cell
        v-for="(item, key) in list"
        :key="key"
        class="examList"
        @click="examinInfo(item, key)"
      >
        <div class="left-img">
          <img v-if="item.csurl != ''" v-lazy="item.csurl" alt="" />
          <img v-else src="@/assets/img/default_bgimg.jpg" alt="" />
        </div>
        <div class="info">
          <div class="infoExamIcon" v-if="item.isfinish">
            <img src="@/assets/img/trained.png" alt="" />
          </div>
          <!-- <p class="examName">招聘类-文秘招聘考试</p> -->
          <div class="toptop">
            <span class="courseName">{{ item.coursename }}</span>
            
          </div>
          <span class="lecturerName"
            >{{ $t("toLearn.LearnTxt4") }}{{ item.teacher_empname }}</span
          >
          <div class="footer">
            <span class="date"> {{ item.studybt }} - {{ item.studyet }}</span>
          </div>
          <div class="footer">
            <span class="date"
              >{{ $t("toLearn.LearnTxt5") }}{{ item.coursehour
              }}{{ $t("toLearn.LearnTxt6") }}</span
            >
            <div class="right">
              <span class="score"
                >{{ item.coursecredit }}{{ $t("toLearn.LearnTxt3") }}</span
              >
            </div>
          </div>
        </div>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
import { getStudyCourseList } from "@api/wxpx.js";
export default {
  data() {
    return {
      userInfo,
      isLoading: false,
      list: [],
      loading: false,
      finished: false,
    };
  },
  methods: {
    examinInfo(item, key) {
      window.sessionStorage.setItem("v8_study_data", JSON.stringify(item));
      window.localStorage.setItem("learningCoursesDataKey"+item.autoid,0)
      window.localStorage.setItem("learningCoursesDataIndex"+item.autoid,0)
      this.$router.push({
        path: "/learningCourses",
        query: {
          mautoid: item.autoid,
        },
      });
    },
    onClickLeft() {
      this.$router.push("/home");
    },
    onClickRight() {},
    onLoad() {
      getStudyCourseList({ empid: this.userInfo.empid }).then((res) => {
        console.log(res.data);
        if (res.iserror == 0) {
          this.list = res.data;
          this.loading = false;
          this.finished = true;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.toLearn {
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .left-img {
    position: relative;
    width: 342px;
    height: 190px;
    overflow: visible;
    background: #ecf2ff;
    opacity: 1;

    text-align: center;
    margin-right: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      display: block;
      max-height: 100% !important;
    }
  }
  .examList {
    padding: 32px;
    .van-cell__value {
      display: flex;

      .info {
        flex: 1;
        position: relative;
        .infoExamIcon{
          position: absolute;
          right: 0;
          top: 0;
          z-index: 99999;
          width: 90px;
          img{
            width: 90px;
          }
        }
        .lecturerName {
          color: #999999;
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 400;
        }
        .toptop {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          .courseName {
            width: 78%;
            line-height: 1.1;
            margin: 0;
            font-size: 30px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
          }
          .right {
            .score {
              font-size: 30px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 27px;
              color: #2b8df0;
            }
            .duration {
              font-size: 24px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 27px;
              color: #333333;
            }
          }
        }
        .footer {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .date {
            color: #999999;
            font-size: 20px;
          }
          .right {
            .score {
              font-size: 30px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 27px;
              color: #2b8df0;
            }
            .duration {
              font-size: 24px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 27px;
              color: #333333;
            }
          }
        }
      }
    }
  }
}
</style>
